export const TAGS = {
  PHYSICAL_COMPUTING: { label: 'Physical Computing', color: '#db8915' },
  CODE_OF_MUSIC: { label: 'Code of Music', color: '#9fa0f4' },
  VIDEO_AND_SOUND: { label: 'Video and Sound', color: '#107c53' },
  ANIMATION: { label: 'Animation', color: '#990D61' },
  ICM: { label: 'ICM: Media', color: '#56bcc9' },
  MUSIC_INTERACTION_DESIGN: {
    label: 'Music Interaction Design',
    color: 'rgb(0, 205, 149)',
  },
  NATURE_OF_CODE: {
    label: 'Nature of Code',
    color: '#A04CA8',
  },
  FAB: {
    label: 'Intro to Fabrication',
    color: '#937901',
  },
  SIGNALS_CALLS_MARCHES: {
    label: 'Signals, Calls, and Marches',
    color: '#a27ae2',
  },
  SPRING_SHOW_21: {
    label: `Spring Show 2021`,
    color: '#d6481d',
  },
  DIGIFAB: {
    label: `Digital Fabrication`,
    color: '#8A3B5B',
  },
  NIME: {
    label: `NIME`,
    color: '#18A',
  },
  HOMEMADE_HARDWARE: {
    label: `Homemade Hardware`,
    color: '#598bf7',
    // isCurrent: true,
  },
  THESIS: {
    label: `Thesis`,
    color: '#b7011d',
    // isCurrent: true,
  },
};
